import moment from "moment";
export default {
    data() {
        return {
            moment: moment,
        }
    },
    props: {
        item: {
            type: Object,
            default: ()=>{},
        },
        type: {
            type: Number,
            default: 1,
        },
        detail: {
            type: Object,
            default: ()=>{},
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toDetail() {
            if (this.type === 1) {
                this.$router.push({
                    name: 'admin-my-order-train-detail',
                    query: {
                        orderNo: this.item.orderNo,
                    }
                })
            } else {
                this.$router.push({
                    name: 'admin-my-order-train-refund-detail',
                    query: {
                        refundNo: this.item.orderNo,
                    }
                })
            }
        }
    }
}
